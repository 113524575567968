import { Input } from "antd";
import React from "react";

const TextAreaField = (
  id,
  name,
  value,
  isRequired,
  error,
  textFieldChangeHandler
) => {
  return (
    <div className="col-sm-6">
      <div className="form-group gray-fill-input mr-b30">
        <label htmlFor="">{isRequired ? "*" + name : name}</label>
        <Input.TextArea
          type="textarea"
          className="form-control"
          name={id}
          id={id}
          defaultValue={value ? value : ""}
          onChange={(e) => {
            textFieldChangeHandler(id, e);
          }}
          maxLength={500}
          placeholder={name}
          // disabled={(row.field_name == "nfc_tag_id" && editFlag) ? true : false}
        />

        {isRequired ? (
          error !== "" ? (
            <p className="error-alert mr-b20">{error}</p>
          ) : null
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TextAreaField;
